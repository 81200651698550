import React from "react"
import styled from "styled-components"

const StyledHeading1 = styled.h1`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  line-height: 1.5;
  margin: 0;
  margin-bottom: ${props => props.marginBottom};
`

const Heading1 = ({
  heading,
  ...rest
}) => {
  return (
    <StyledHeading1 {...rest}>
      {heading}
    </StyledHeading1>
  )
}

export default Heading1
