import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { StaticImage } from "gatsby-plugin-image"
// components
import Layout from "../components/layout"
import Heading1 from "../components/atoms/heading_1"
import SEO from "../components/seo"

const StyledNotFound = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-column-end: -2;
  grid-column-start: 2;
  height: calc(100vh - 68px);
  justify-content: center;
  padding: 10px 0 25px;
`

const NotFound = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return (
    <Layout hideNavigation={true}>
      <StyledNotFound>
        <Heading1
          fontSize={
            isMobile
              ? "large"
              : "x-large"
          }
          marginBottom="50px"
          heading="お探しのページが見つかりませんでした。"
        />
        <StaticImage
          src="../images/404.png"
          alt="" //
          layout="fixed"
          width={250}
        />
      </StyledNotFound>
    </Layout>
  )
}

export default NotFound

export const Head = ({ location }) => (
  <SEO
    title="ページが見つかりません"
    pathname={location.pathname}
  />
)
